<template>
  <div>
    <v-dialog
      content-class="custom-dialog-content"
      v-model="accountingCardDialog"
      @click:outside="$emit('update:accountingCardDialog', false)"
      max-width="70%"
    >
      <AccountingCardRecordsTable v-if="accountingCardDialog" :listSkeleton.sync="skeleton" :accountingCardDialog.sync="dialog" :model="modelData" />
    </v-dialog>
  </div>
</template>
<script>
import AccountingCardRecordsTable from "@/components/accountingCardRecords/Table/AccountingCardRecordsTable.vue";

export default {
  name: 'AccountingCardRecordsDialog',
  components: {
    AccountingCardRecordsTable
  },
  props: ['accountingCardDialog', 'listSkeleton', 'modelData'],
  data() {
    return {
      dialog: false,
      skeleton: false
    }
  },
  watch: {
    async dialog(val) {
      if (!val) {
        this.$emit('update:accountingCardDialog', false)
      }
    },
    accountingCardDialog(val) {
      if (!val) {
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    }
  },
}
</script>

<style>
.custom-dialog-content {
  box-shadow: none !important;
  overflow-y: initial !important;
}
:deep(.v-dialog) {
  overflow: hidden !important;
}
</style>