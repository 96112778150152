<template>
  <div>
    <v-card class="mx-auto" outlined>
      <ListSkeleton v-if="listSkeleton" />
      <v-card-text class="text-h5" v-else-if="accountCardDataList">
        <v-card-text class="pa-0 text-end">
          <v-icon
            class="mt-2 primary--text"
            @click="$emit('update:accountingCardDialog', false)"
            >mdi-close</v-icon
          >
        </v-card-text>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          fixed-header
          height="250px"
          :items="accountCardDataList"
          style="word-break: break-word, overflow: auto"
          item-class="py-4"
          hide-default-footer
          :search="search"
          disable-pagination
        >
          <template v-slot:[`item.account_name`]="{ item }">
            <span>{{ accountNameHebrew.includes(item.account_name) && item.account_name ? $t(`accountingCardReport.${item.account_name}`) : item.account_name }}</span>
          </template>
          <template v-slot:[`item.documentDate`]="{ item }">
            <span>{{ item.documentDate && profileById.date_format ? formatDateDDMMYYYY(item.documentDate) : item.documentDate }}</span>
          </template>
          <template v-slot:[`item.valueDate`]="{ item }">
            <span>{{ item.valueDate && profileById.date_format ? formatDateDDMMYYYY(item.valueDate) : item.valueDate }}</span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <span>{{ item.details ? $t(`accountingCardReport.${item.details.split("-")[0]}`) + '-' : null }}{{ item.details ? item.details.split("-")[1] + '-' : null }}{{ item.details ? item.details.split("-")[2] : null }}</span>
          </template>
          <template v-slot:[`item.debit`]="{ item }">
            <span style="direction:ltr" class="d-inline-block">{{ !item.debit ? null : numberWithCommas(item.debit) }}</span>
          </template>
          <template v-slot:[`item.credit`]="{ item }">
            <span style="direction:ltr" class="d-inline-block">{{ !item.credit ? null : numberWithCommas(item.credit) }}</span>
          </template>
          <template v-slot:[`item.balance`]="{ item }">
            <span style="direction:ltr" class="d-inline-block">{{ item.balance === '' ? '' : numberWithCommas(item.balance) }}</span>
          </template>
          <template v-slot:top>
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="9"> </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
        </v-data-table>
        <v-row class="d-flex" style="width: 75% !important">
          <v-col class="mx-auto" cols="12">
            <span class="pl-3 pt-2 text-subtitle-2">{{ $t('common.numberOfTotalRows') }} {{accountCardDataList.length}}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else>{{ $t("common.noDataFound") }}</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import {formatDateDDMMYYYY} from '@/utils';

export default {
  name: "AccountingCardRecordsTable",
  props: ["listSkeleton", 'accountingCardDialog', 'model'],
  components: {
    ListSkeleton,
  },
  data() {
    return {
      search: this.$route.query.search || "",
      accountNameHebrew: [
        "Income","Expenses","Funds","Banks","Others","Employee","Customer","Supplier","Item","Bank","Checks","Cash","VAT","VATExemptedIncome","OverseasIncome","CustomerWithholdingTax","PurchasesVAT","SupplierWithholdingTax","EquipmentPurchaseVAT","ChequeFunds","CashFunds","IncomeVAT","Cheque", "ReceiptTaxtInvoice"
      ],
    };
  },
  methods: {
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    async paginate() {
      await this.$store.dispatch("trialBalance/GetAccountCardData",this.model.accountNo);
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
  },
  async mounted() {
    this.$emit("update:listSkeleton", true);
    await this.$store.dispatch("trialBalance/GetAccountCardData",this.model.accountNo);
    await this.$store.dispatch('profile/GetCompanyById');
    this.$emit("update:listSkeleton", false);
  },
  watch: {
    params: {
      handler() {
        this.paginate();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.search,
      };
    },
    headers() {
      return [
        { text: this.$t('accountingCardReport.accountNo'), align: "start", value: "accountNo", width: '100px', class: 'px-1', sortable: false },
        { text: this.$t('accountingCardReport.accountName'), value: "account_name", width: '120px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.documentDate'), value: "documentDate", width: '138px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.valueDate'), align: "left", value: "valueDate", width: '138px', sortable: false },
        { text: this.$t('accountingCardReport.details'), value: "details", width: '140px', sortable: false },
        { text: this.$t('accountingCardReport.journalEntry'), value: "journalEntry", width: '100px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.debit'), value: "debit", width: '100px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.credit'), value: "credit", width: '100px', class: 'px-2', sortable: false },
        { text: this.$t('accountingCardReport.balance'), value: "balance", width: '100px', class: 'px-2', sortable: false },   
      ]
    },
    ...mapGetters({
      accountCardDataList: 'trialBalance/accountCardDataList',
      profileById: 'profile/profileById',
    }),
  },
  beforeDestroy() {
    this.$store.commit('trialBalance/SET_ACCOUNT_CARD_DATA', []);
  }
};
</script>
<style scoped>
::v-deep .v-pagination__item {
  word-break: keep-all;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>