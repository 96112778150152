<template>
  <div>
    <div v-if="this.$route.name === 'TrialBalance'">
      <TrialBalance />
    </div>
    <router-view />
  </div>
</template>
<script>
import TrialBalance from "@/views/companyApp/trialBalance/TrialBalance.vue";

export default {
  name: 'TrialBalanceParent',
  components: {
    TrialBalance,
  }
}
</script>